import React from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="notFound">
      <div className="notFound__wrapper">
        <div className="notFound__text">
          <h1 className="notFound__heading">Side ikke funnet</h1>
          <p className="notFound__paragraph">Du har havnet på en side som ikke eksister... Det var virkelig trist.</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
